import React from "react";
import Style from "../../assets/scss/heroText.module.scss";

export default function HeroText() {
  return (
    <React.Fragment>
      <section className={Style.mainSection}>
        <h1>
          We’re gonna make you an
          <br />
          offer you can’t refuse
        </h1>
        <p>Check out some of our recent design projects.</p>
      </section>
    </React.Fragment>
  );
}
