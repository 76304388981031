import React, { useEffect, useState } from "react"; //useRef,
import {
  INannualAdvancedPrice_saving,
  INannualAdvanced_CIPrice_saving,
  INannualStandardPrice_saving,
  INquarterAdvancedPrice_saving,
  INquarterAdvanced_CIPrice_saving,
  INquarterStandardPrice_saving,
  annualAdvancedPrice_saving,
  annualAdvanced_CIPrice_saving,
  annualStandardPrice_saving,
  quarterAdvancedPrice_saving,
  quarterAdvanced_CIPrice_saving,
  quarterStandardPrice_saving,
} from "../../Plans/Price/Price";
import "../../assets/scss/global.scss";
import Style from "../../assets/scss/package.module.scss";

export default function Package(props) {
  // const [cI, setCI] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detCountry, setDetCountry] = useState("IN");

  useEffect(() => {
    if (!sessionStorage.getItem("detCountry")) {
      fetch("https://api.db-ip.com/v2/free/self")
        .then((res) => res.json())
        .then((data) => {
          setDetCountry(data.countryCode);
          sessionStorage.setItem("detCountry", data.countryCode);
          setLoading(false);
        })
        .catch(function (err) {
          console.log(err);
          setLoading(false);
        });
    } else {
      setDetCountry(sessionStorage.getItem("detCountry"));
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="box">
        <div className={Style.packages}>
          {/* Package One */}
          <div className={`${Style.packageBoth} ${Style.marginRight}`}>
            <div className={Style.packageTop}>
              <h3>Graphics</h3>
              <h1>
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    {detCountry === "IN" ? (
                      <>
                        ₹
                        {props.plan.activePlan === 1
                          ? props.plan.INannualStandardPrice
                          : props.plan.activePlan === 2
                          ? props.plan.INquarterStandardPrice
                          : props.plan.activePlan === 3
                          ? props.plan.INmonthStandardPrice
                          : ""}
                      </>
                    ) : (
                      <>
                        $
                        {props.plan.activePlan === 1
                          ? props.plan.annualStandardPrice
                          : props.plan.activePlan === 2
                          ? props.plan.quarterStandardPrice
                          : props.plan.activePlan === 3
                          ? props.plan.monthStandardPrice
                          : ""}
                      </>
                    )}
                  </>
                )}
                <span className="sub">/ {props.plan.duration}</span>
              </h1>
              {props.plan.activePlan === 2 ? (
                <p style={{ fontWeight: "bold" }}>
                  <span style={{ color: "#545f75" }}>Billed Quarterly,</span>{" "}
                  <span style={{ color: "#ff6510" }}>
                    {" "}
                    SAVE{" "}
                    {detCountry === "IN"
                      ? INquarterStandardPrice_saving
                      : quarterStandardPrice_saving}{" "}
                  </span>
                </p>
              ) : props.plan.activePlan === 1 ? (
                <p style={{ fontWeight: "bold" }}>
                  <span style={{ color: "#545f75" }}>Billed Yearly,</span>{" "}
                  <span style={{ color: "#ff6510" }}>
                    {" "}
                    SAVE{" "}
                    {detCountry === "IN"
                      ? INannualStandardPrice_saving
                      : annualStandardPrice_saving}{" "}
                  </span>
                </p>
              ) : null}
            </div>
            <div className={Style.packageMiddle}>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Unlimited Requests &#38; Revisions
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Unlimited Brands &#38; Users
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Digital, Print and Basic Logo
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                1-2 outputs per business day
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Designated Designer
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Premium Stock Assets
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Source File Included*
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                1 project at a time
              </p>
              {/* <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>Pitch Decks</p>
                            <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>2-3 sec Animated GIFs</p>
                            <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>Custom Illustration*</p> */}
            </div>
            <div className={Style.packageBottom}>
              <a
                href={
                  props.plan.activePlan === 1
                    ? props.plan.annualStandardLink
                    : props.plan.activePlan === 2
                    ? props.plan.quarterStandardLink
                    : props.plan.activePlan === 3
                    ? props.plan.monthStandardLink
                    : "/noLink"
                }
                className={Style.buyButton}
              >
                Get Started
              </a>
              <a href={props.plan.freeTrialLink}>Try Free for 7 days</a>
            </div>
          </div>
          {/* Package Two */}
          <div
            className={`${Style.packageTwo} ${Style.packageBoth} ${Style.marginRight}`}
            style={{ background: "#393F4D" }}
          >
            <div className={Style.popular}>
              Popular
              {/* <img src={require("../../assets/icons/Star_Popular.svg").default} alt="Popular" /> */}
            </div>

            <div className={Style.packageTop}>
              <h3>Graphics+</h3>
              {detCountry === "IN" && (
                <del className={Style.deletePrice}>
                  {" "}
                  {props.plan.activePlan === 3
                    ? "₹20000"
                    : props.plan.activePlan === 2
                    ? "₹18000"
                    : props.plan.activePlan === 1
                    ? "17000"
                    : ""}{" "}
                  <span className="sub">/ {props.plan.duration}</span>
                </del>
              )}

              <h1>
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    {detCountry === "IN" ? (
                      <>
                        ₹
                        {
                          // cI ? props.plan.activePlan === 1 ?
                          //     props.plan.INannualAdvanced_CIPrice :
                          //     props.plan.activePlan === 2 ?
                          //         props.plan.INquarterAdvanced_CIPrice :
                          //         props.plan.activePlan === 3 ?
                          //             props.plan.INmonthAdvanced_CIPrice : "" :
                          <>
                            {props.plan.activePlan === 1
                              ? props.plan.INannualAdvancedPrice
                              : props.plan.activePlan === 2
                              ? props.plan.INquarterAdvancedPrice
                              : props.plan.activePlan === 3
                              ? props.plan.INmonthAdvancedPrice
                              : ""}
                          </>
                        }
                      </>
                    ) : (
                      <>
                        $
                        {/* {cI ? props.plan.activePlan === 1 ?
                                                props.plan.annualAdvanced_CIPrice :
                                                props.plan.activePlan === 2 ?
                                                    props.plan.quarterAdvanced_CIPrice :
                                                    props.plan.activePlan === 3 ?
                                                        props.plan.monthAdvanced_CIPrice : "" : */}
                        <>
                          {props.plan.activePlan === 1
                            ? props.plan.annualAdvancedPrice
                            : props.plan.activePlan === 2
                            ? props.plan.quarterAdvancedPrice
                            : props.plan.activePlan === 3
                            ? props.plan.monthAdvancedPrice
                            : ""}
                        </>
                        {/* } */}
                      </>
                    )}
                  </>
                )}
                <span className="sub">/ {props.plan.duration}</span>

                {props.plan.activePlan === 2 ? (
                  <p style={{ fontWeight: "bold" }}>
                    <span style={{ color: "white" }}>Billed Quarterly,</span>{" "}
                    <span style={{ color: "#ff6510" }}>
                      {" "}
                      SAVE{" "}
                      {detCountry === "IN"
                        ? INquarterAdvancedPrice_saving
                        : quarterAdvancedPrice_saving}{" "}
                    </span>
                  </p>
                ) : props.plan.activePlan === 1 ? (
                  <p style={{ fontWeight: "bold" }}>
                    <span style={{ color: "white" }}>Billed Yearly, </span>{" "}
                    <span style={{ color: "#ff6510" }}>
                      {" "}
                      SAVE{" "}
                      {detCountry === "IN"
                        ? INannualAdvancedPrice_saving
                        : annualAdvancedPrice_saving}{" "}
                    </span>
                  </p>
                ) : null}
              </h1>
              {/* <p>
                Queue up as many design requests you want. Need to change color
              </p> */}
              {/* <div className={Style.customCI}>
                                Custom illustrations
                                <label className={Style.switch}>
                                    <input type="checkbox" onClick={customIllustrarions} />
                                    <span className={`${Style.slider} ${Style.round}`}></span>
                                </label>
                            </div> */}
            </div>
            <div className={Style.packageMiddle}>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                All in Graphics
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                GIFs (2-3 sec)
              </p>
              <p className="d-flex align-items-start">
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                <p style={{ margin: "0" }}>
                  Presentation Decks
                  <br />
                  <p style={{ fontSize: "10px" }}>
                    (Editable—PPT & Google Slides)
                  </p>
                </p>
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Landing Pages
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Brand Guidelines
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Custom Illustrations
              </p>
              {/* <p ref={customI} className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>Custom Illustration*</p> */}
            </div>
            <div className={Style.packageBottom}>
              <a
                href={
                  // cI ? props.plan.activePlan === 1 ?
                  //     props.plan.annualAdvanced_CILink :
                  //     props.plan.activePlan === 2 ?
                  //         props.plan.quarterAdvanced_CILink :
                  //         props.plan.activePlan === 3 ?
                  //             props.plan.monthAdvanced_CILink : "/NoLink" :
                  props.plan.activePlan === 1
                    ? props.plan.annualAdvancedLink
                    : props.plan.activePlan === 2
                    ? props.plan.quarterAdvancedLink
                    : props.plan.activePlan === 3
                    ? props.plan.monthAdvancedLink
                    : "/NoLink"
                }
                className={Style.buyButton}
              >
                Get Started
              </a>
              <div style={{ height: "38px" }}></div>
              {/* <a href={props.plan.freeTrialLink}>Try Free for 7 days</a> */}
            </div>
          </div>
          {/* Package Third */}
          <div className={Style.packageBoth}>
            <div className={Style.packageTop}>
              <h3>Essential Videos</h3>
              {detCountry === "IN" && (
                <del>
                  {" "}
                  {props.plan.activePlan === 3
                    ? "₹25000"
                    : props.plan.activePlan === 2
                    ? "₹22500"
                    : props.plan.activePlan === 1
                    ? "₹21250"
                    : ""}{" "}
                  <span className="sub">/ {props.plan.duration}</span>
                </del>
              )}

              <h1>
                {loading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    {detCountry === "IN" ? (
                      <>
                        ₹
                        {props.plan.activePlan === 1
                          ? props.plan.INannualAdvanced_CIPrice
                          : props.plan.activePlan === 2
                          ? props.plan.INquarterAdvanced_CIPrice
                          : props.plan.activePlan === 3
                          ? props.plan.INmonthAdvanced_CIPrice
                          : ""}
                      </>
                    ) : (
                      <>
                        $
                        {props.plan.activePlan === 1
                          ? props.plan.annualAdvanced_CIPrice
                          : props.plan.activePlan === 2
                          ? props.plan.quarterAdvanced_CIPrice
                          : props.plan.activePlan === 3
                          ? props.plan.monthAdvanced_CIPrice
                          : ""}
                      </>
                    )}
                  </>
                )}
                <span className="sub">/ {props.plan.duration}</span>

                {props.plan.activePlan === 2 ? (
                  <p style={{ fontWeight: "bold" }}>
                    <span style={{ color: "#545f75" }}>Billed Quarterly,</span>{" "}
                    <span style={{ color: "#ff6510" }}>
                      {" "}
                      SAVE{" "}
                      {detCountry === "IN"
                        ? INquarterAdvanced_CIPrice_saving
                        : quarterAdvanced_CIPrice_saving}{" "}
                    </span>
                  </p>
                ) : props.plan.activePlan === 1 ? (
                  <p style={{ fontWeight: "bold" }}>
                    <span style={{ color: "#545f75" }}>Billed Yearly,</span>{" "}
                    <span style={{ color: "#ff6510" }}>
                      {" "}
                      SAVE{" "}
                      {detCountry === "IN"
                        ? INannualAdvanced_CIPrice_saving
                        : annualAdvanced_CIPrice_saving}{" "}
                    </span>
                  </p>
                ) : null}
              </h1>
              {/* <p>
                Queue up as many design requests you want. Need to change color
              </p> */}
            </div>
            <div className={Style.packageMiddle}>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Unlimited Requests &#38; Revisions
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Unlimited Brands &#38; Users
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                2-4 day turnaround
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Designated Video Editor
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Premium Stock Assets
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                1 project at a time
              </p>
              <p>
                <span>
                  <img
                    src={require("../../assets/icons/tick.svg").default}
                    alt="TickMark"
                  />
                </span>
                Source File Included
              </p>
              {/* <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>Pitch Decks</p>
                            <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>2-3 sec Animated GIFs</p>
                            <p className={Style.hideFeatures}><span><img src={require("../../assets/icons/tick.svg").default} alt="TickMark" /></span>Custom Illustration*</p> */}
            </div>
            <div className={Style.packageBottom}>
              <a
                href={
                  props.plan.activePlan === 1
                    ? props.plan.annualStandardLink
                    : props.plan.activePlan === 2
                    ? props.plan.quarterStandardLink
                    : props.plan.activePlan === 3
                    ? props.plan.monthStandardLink
                    : "/noLink"
                }
                className={Style.buyButton}
              >
                Get Started
              </a>
              <div style={{ height: "38px" }}></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
