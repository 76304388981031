import AnnualTab from "../components/AnnualTab/AnnualTab";
import MonthTab from "../components/MonthTab/MonthTab";
import QuarterTab from "../components/QuarterTab/QuarterTab";
import Dashboard from "../pages/Dashboard";
import Error from "../pages/Error/Error";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Login from "../pages/Login/Login";
import SignUp from "../pages/SignUp/SignUp";

const errorRoute = [{ id: 1, path: "*", component: Error }];

const tabRoute = [
  { id: 1, path: "/annual", component: AnnualTab },
  { id: 2, path: "/quarter", component: QuarterTab },
  { id: 3, path: "/month", component: MonthTab },
  { id: 3, path: "/update-faq", component: Dashboard },
];

const authRoutes = [
  { id: 1, path: "/login", component: Login },
  { id: 2, path: "/registration", component: SignUp },
  { id: 3, path: "/forgot_password", component: ForgotPassword },
];

export { errorRoute, tabRoute, authRoutes };

