import React, { useState } from "react";
import {
  annualAdvancedLink,
  annualAdvanced_CILink,
  annualStandardLink,
  freeTrialLink,
} from "../../Plans/Links/Links";
import {
  INannualAdvancedPrice,
  INannualAdvanced_CIPrice,
  INannualStandardPrice,
  annualAdvancedPrice,
  annualAdvanced_CIPrice,
  annualStandardPrice,
} from "../../Plans/Price/Price";
import Banner from "../Banner/Banner";
import Banner2 from "../Banner/Banner2";
import VatTxtBanner from "../Banner/VatTxtBanner";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeroText from "../HeroText/HeroText";
import Package from "../Packages/Package";
import PackageMobile from "../Packages/PackageMobile";
import ScopeService from "../ScopeService/ScopeService";
import Tabs from "../Tabs/Tabs";

export default function AnnualTab() {
  const [desktopPackage, setDesktopPackage] = useState(true);

  setInterval(() => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    const width = getWidth();
    // console.log(width)
    if (width >= 524) {
      setDesktopPackage(true);
    } else {
      setDesktopPackage(false);
    }
  }, 1000);

  const Plan = {
    activePlan: 1,
    duration: "mo",
    annualStandardPrice: annualStandardPrice,
    annualAdvancedPrice: annualAdvancedPrice,
    annualAdvanced_CIPrice: annualAdvanced_CIPrice,
    INannualStandardPrice: INannualStandardPrice,
    INannualAdvancedPrice: INannualAdvancedPrice,
    INannualAdvanced_CIPrice: INannualAdvanced_CIPrice,
    annualStandardLink: annualStandardLink,
    annualAdvancedLink: annualAdvancedLink,
    annualAdvanced_CILink: annualAdvanced_CILink,
    freeTrialLink: freeTrialLink,
  };
  return (
    <React.Fragment>
      <Header />
      <HeroText />
      <Tabs active={1} />
      {desktopPackage ? <Package plan={Plan} /> : <PackageMobile plan={Plan} />}
      <VatTxtBanner />
      <Banner />
      <ScopeService />
      <FAQ />
      <Banner2 />
      <Footer />
    </React.Fragment>
  );
}
