// Month Payment Price
const monthStandardPrice = 299;
const monthAdvancedPrice = 399;
const monthAdvanced_CIPrice = 499;

// Quarter Payment Price
const quarterStandardPrice = 269;
const quarterAdvancedPrice = 359;
const quarterAdvanced_CIPrice = 449;

// Annual Payment Price
const annualStandardPrice = 254;
const annualAdvancedPrice = 339;
const annualAdvanced_CIPrice = 424;

// saving in dollar

// Quarter Payment Price saving
const quarterStandardPrice_saving = "$" + 90;
const quarterAdvancedPrice_saving = "$" + 120;
const quarterAdvanced_CIPrice_saving = "$" + 150;

// Annual Payment Price saving
const annualStandardPrice_saving = "$" + 540;
const annualAdvancedPrice_saving = "$" + 720;
const annualAdvanced_CIPrice_saving = "$" + 900;

// Indian Prices
// Annual Payment Price
const INannualStandardPrice = 11900;
const INannualAdvancedPrice = 14450;
const INannualAdvanced_CIPrice = 17000;

// Quarter Payment Price
const INquarterStandardPrice = 12600;
const INquarterAdvancedPrice = 15300;
const INquarterAdvanced_CIPrice = 18000;

// Month Payment Price
const INmonthStandardPrice = 14000;
const INmonthAdvancedPrice = 17000;
const INmonthAdvanced_CIPrice = 20000;

// saving in rupi

// Quarter Payment Price saving
const INquarterStandardPrice_saving = "₹" + 4200;
const INquarterAdvancedPrice_saving = "₹" + 5100;
const INquarterAdvanced_CIPrice_saving = "₹" + 6000;

// Annual Payment Price saving
const INannualStandardPrice_saving = "₹" + 25200;
const INannualAdvancedPrice_saving = "₹" + 30600;
const INannualAdvanced_CIPrice_saving = "₹" + 36000;

export {
  annualStandardPrice,
  annualAdvancedPrice,
  annualAdvanced_CIPrice,
  quarterStandardPrice,
  quarterAdvancedPrice,
  quarterAdvanced_CIPrice,
  monthStandardPrice,
  monthAdvancedPrice,
  monthAdvanced_CIPrice,
  INannualStandardPrice,
  INannualAdvancedPrice,
  INannualAdvanced_CIPrice,
  INquarterStandardPrice,
  INquarterAdvancedPrice,
  INquarterAdvanced_CIPrice,
  INmonthStandardPrice,
  INmonthAdvancedPrice,
  INmonthAdvanced_CIPrice,
  quarterStandardPrice_saving,
  quarterAdvancedPrice_saving,
  quarterAdvanced_CIPrice_saving,
  annualStandardPrice_saving,
  annualAdvancedPrice_saving,
  annualAdvanced_CIPrice_saving,
  INquarterStandardPrice_saving,
  INquarterAdvancedPrice_saving,
  INquarterAdvanced_CIPrice_saving,
  INannualStandardPrice_saving,
  INannualAdvancedPrice_saving,
  INannualAdvanced_CIPrice_saving,
};
