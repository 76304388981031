import React from "react";
import Style from "../../assets/scss/banner.module.scss";
import "../../assets/scss/global.scss";

export default function Banner() {
  return (
    <React.Fragment>
      <div className="box">
        <div className={Style.banner}>
          <div style={{ alignItems: "center", gap: "5" }}>
            <h1>Looking for a customized solution?</h1>
            <p
              style={{
                fontSize: "10px",
                color: "white",
                marginTop: "-10px",
              }}
            >
              (For high volume projects)
            </p>
          </div>
          <a
            className={`button  ${Style.schedule_A_call_btn}`}
            rel="noreferrer"
            target="_blank"
            href="https://calendly.com/doodlspace"
          >
            Schedule a Call
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
