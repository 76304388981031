import {
  faBars,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react"; //, useEffect
import rightSiteLogo from "../../assets/logo/Asset_1.svg";
import brandLogo from "../../assets/logo/doodlspaceLogo.png";
import "../../assets/scss/global.scss";
import Style from "../../assets/scss/header.module.scss";

export default function Header() {
  const [nav, setNav] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const [isOpen, seIsOpen] = useState(true);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const [resourcesDropdown, setResourcesDropdown] = useState(false);

  const navToggle = (e) => {
    if (e.target.checked) {
      setNav(e.target.checked);
      setNavActive(true);
    } else {
      setNav(e.target.checked);
    }
  };

  setInterval(() => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    const width = getWidth();
    if (navActive && width >= 824) {
      setNavActive(false);
      setNav(false);
    }
  }, 1000);
  return (
    <React.Fragment>
      <header>
        <div className="box">
          <div className={Style.header}>
            <div className={Style.logoDiv}>
              <a href="https://doodlspace.com" target="_blank">
                <img className={Style.Logo} src={brandLogo} alt="" />
              </a>
            </div>
            <div className={!nav ? Style.dMode : Style.mMode}>
              <nav>
                <ul>
                  <li>
                    <div className={Style.dropdown}>
                      {!nav ? (
                        <>
                          <button className={Style.dropbtn}>
                            Services
                            <span>
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          </button>
                          <div className={Style.dropdownContent}>
                            <a
                              href="https://doodlspace.com/services-startups/"
                              target="_blank"
                            >
                              Startups
                            </a>
                            <a
                              href="https://doodlspace.com/services-marketing-team/"
                              target="_blank"
                            >
                              Marketing Teams
                            </a>
                            <a
                              href="https://doodlspace.com/services-agencies/"
                              target="_blank"
                            >
                              Agencies
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className={Style.dropbtn}
                            onClick={() => {
                              setServicesDropdown(!servicesDropdown);
                            }}
                          >
                            Services
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  servicesDropdown ? faCaretUp : faCaretDown
                                }
                              />
                            </span>
                          </button>
                          <div
                            className={Style.dropdownContent}
                            style={{
                              display: servicesDropdown ? "block" : "none",
                            }}
                          >
                            <a
                              href="https://doodlspace.com/services-startups/"
                              target="_blank"
                            >
                              Startups
                            </a>
                            <a
                              href="https://doodlspace.com/services-marketing-team/"
                              target="_blank"
                            >
                              Marketing Teams
                            </a>
                            <a
                              href="https://doodlspace.com/services-agencies/"
                              target="_blank"
                            >
                              Agencies
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                  <li>
                    <a href="https://doodlspace.com/portfolio/" target="_blank">
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <div className={Style.dropdown}>
                      {!nav ? (
                        <>
                          <button className={Style.dropbtn}>
                            Resources
                            <span>
                              <FontAwesomeIcon icon={faCaretDown} />
                            </span>
                          </button>
                          <div className={Style.dropdownContent}>
                            <a
                              href="https://doodlspace.crunch.help/"
                              target="_blank"
                            >
                              Knowledge Base
                            </a>
                            <a
                              href="https://doodlspace.com/blogs/"
                              target="_blank"
                            >
                              Blogs
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <button
                            className={Style.dropbtn}
                            onClick={() => {
                              setResourcesDropdown(!resourcesDropdown);
                            }}
                          >
                            Resources
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  resourcesDropdown ? faCaretUp : faCaretDown
                                }
                              />
                            </span>
                          </button>
                          <div
                            className={Style.dropdownContent}
                            style={{
                              display: resourcesDropdown ? "block" : "none",
                            }}
                          >
                            <a
                              href="https://doodlspace.crunch.help/"
                              target="_blank"
                            >
                              Knowledge Base
                            </a>
                            <a
                              href="https://doodlspace.com/blogs/"
                              target="_blank"
                            >
                              Blogs
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                  <li>
                    <a
                      href="https://subscription.doodlspace.com/"
                      className={Style.active}
                      target="_blank"
                    >
                      Pricing
                    </a>
                  </li>
                </ul>
              </nav>
              <div
                className={`${Style.others_nav_links} ${Style.toggle_display_mobile_view}`}
              >
                <ul className={Style.toggle_display_mobile_view_item}>
                  <li>
                    <a
                      href="https://desk.zoho.in/portal/doodlspacedesign/en/signin"
                      className={Style.Login}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      className={Style.book_a_Demo}
                      href="https://calendly.com/doodlspace"
                      target="_blank"
                    >
                      Book a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={`${Style.others_nav_links} ${Style.toggle_display_desktop_view}`}
            >
              <ul>
                <li>
                  <a
                    className={Style.bookDemo}
                    href="https://calendly.com/doodlspace"
                    target="_blank"
                  >
                    Book a Demo
                  </a>
                </li>
                <li>
                  <a
                    href="https://desk.zoho.in/portal/doodlspacedesign/en/signin"
                    className={Style.Login}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Login
                  </a>
                </li>
                <li>
                  <a
                    href="https://bridge.doodlspace.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={Style.Beta_logo_wrapper}
                  >
                    <img alt="img" src={rightSiteLogo} />
                  </a>
                </li>
              </ul>
            </div>
            <label onClick={() => seIsOpen(!isOpen)} htmlFor="navBarIcon">
              <span>{isOpen ? <FontAwesomeIcon icon={faBars} /> : "X"}</span>
            </label>
            <input type="checkbox" onChange={navToggle} id="navBarIcon" />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

// some chnages
