import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMapMarkerAlt,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Style from "../../assets/scss/footer.module.scss";
import "../../assets/scss/global.scss";
import FooterMobile from "./FooterMobile";

export default function Footer() {
  return (
    <React.Fragment>
      <footer>
        <div className={Style.boxForFooter}>
          <div className={Style.mainFooter}>
            <section className={Style.One}>
              <Link to="/" className={Style.footerLogo}></Link>
            </section>

            <section className={Style.Two}>
              <ul>
                <li> Company </li>

                <li>
                  <a href="https://doodlspace.com/about-us">About Us</a>
                </li>
                <li>
                  <a href="https://doodlspace.com/portfolio/">Our Work</a>
                </li>

                {/* <li><a href="/">Careers</a></li> */}
              </ul>
            </section>
            <section className={Style.Three}>
              <ul>
                <li>Service</li>
                <li>
                  <a href="https://doodlspace.com/services-startups/">
                    For Start Ups/Small Businesses
                  </a>
                </li>
                <li>
                  <a href="https://doodlspace.com/services-marketing-team/">
                    For Marketing Teams
                  </a>
                </li>
                <li>
                  <a href="https://doodlspace.com/services-agencies/">
                    For Agencies
                  </a>
                </li>
                <li>
                  <a href="https://subscription.doodlspace.com/">Pricing</a>
                </li>
              </ul>
            </section>
            <section className={Style.Four}>
              <ul>
                <li>Legal</li>
                <li>
                  <a href="/">Terms of Services</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
              </ul>
            </section>
            <section className={Style.Five}>
              <ul>
                <li>Resources</li>
                <li>
                  <a href="https://doodlspace.com/blogs/">Blog</a>
                </li>
                <li>
                  <a href="https://doodlspace.com/services-startups/#">
                    Knowledge Base
                  </a>
                </li>
                {/* <li><a href="/">Guides</a></li> */}
                {/* <li><a href="/">Press Kit</a></li> */}
              </ul>
            </section>

            <section className={Style.Six}>
              <ul>
                <li>Contact</li>

                <li>
                  <a href="tel:+919123961265">
                    <span>
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </span>
                    +91 9123961265
                  </a>
                </li>
                <li>
                  <a href="mailto:info@doodlspace.com">
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    info@doodlspace.com
                  </a>
                </li>
                <li>
                  <a href="/">
                    <span>
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    </span>

                    <div>
                      <span className={Style.smallFont12}>
                        {" "}
                        660-E, A-Wing, 6th Floor, B.D. Patel House,{" "}
                      </span>
                      <span className={Style.smallFont12}>
                        Naranpura, Ahmedabad 380014
                      </span>
                    </div>
                  </a>
                </li>
              </ul>
              <p>Follow us on:</p>
              <ul className={Style.social}>
                <li>
                  <a href="https://linkedin.com/company/doodlspace">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
                <li>
                  <a href="https://facebook.com/doodlspace">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/doodlspace">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
              </ul>
            </section>

            <FooterMobile />
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
