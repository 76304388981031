import React from "react";
import Style from "../../assets/scss/banner.module.scss";
import "../../assets/scss/global.scss";

export default function Banner2() {
  return (
    <React.Fragment>
      <div className="box">
        <div className={Style.bannerTwo}>
          <h1>Couldn’t find what you are looking for?</h1>
          <p>
            <a
              className={`button ${Style.buttonBanner}`}
              style={{ width: "250px", padding: "16px 20px" }}
              href="https://doodlspace.crunch.help/en/"
            >
              Visit help centre
            </a>
            <a
              className={`buttonTwo ${Style.buttonBanner}`}
              target="_blank"
              // href="https://calendly.com/doodlspace"
              href="https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html"
            >
              Contact Us
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
