// Standard Data
const StandardData = [
  {
    name: "Logo Design",
    detail: "Queue up as many design requests as you want.",
    img: "LogoDesign.png",
  },
  {
    name: "Digital",
    detail: "Queue up as many design requests as you want.",
    img: "Digital.png",
  },
  {
    name: "Print Collaterals",
    detail: "Queue up as many design requests as you want.",
    img: "Print.png",
  },
  {
    name: "Merch",
    detail: "Queue up as many design requests as you want.",
    img: "Merch.png",
  },
];
// Advanced Data
const AdvancedData = [
  {
    name: "Pitch Decks",
    detail: "Queue up as many design requests as you want.",
    img: "PitchDecks.png",
  },
  {
    name: "2-3 sec Animated GIFs",
    detail: "Queue up as many design requests as you want.",
    img: "Gif.png",
  },
  {
    name: "Landing Pages",
    detail: "Queue up as many design requests as you want.",
    img: "LandingPage.png",
  },
  {
    name: "Custom Illustration",
    detail: "Queue up as many design requests as you want.",
    img: "CustomIllustration.png",
  },
];
const EssentialVideos = [
  {
    name: "Reel Videos",
    detail: "Queue up as many design requests as you want.",
    img: "ReelVideo.png",
  },
  {
    name: "Logo Animation",
    detail: "Queue up as many design requests as you want.",
    img: "LogoAnimation.png",
  },
  {
    name: "Character Animation",
    detail: "Queue up as many design requests as you want.",
    img: "CharacterAnimation.png",
  },
  {
    name: "Video Editing",
    detail: "Queue up as many design requests as you want.",
    img: "VideoEditing.png",
  },
];

export { StandardData, AdvancedData, EssentialVideos };
