import React from "react";
import Style from "../../assets/scss/banner.module.scss";
const VatTxtBanner = () => {
  return (
    <div className={Style.vat_txt_banner_container}>
      <p className="">
        Local taxes (VAT, GST, etc.) will be charged in addition to the prices
        mentioned.{" "}
      </p>
    </div>
  );
};

export default VatTxtBanner;
