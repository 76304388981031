import React from "react";
import { NavLink } from "react-router-dom";
import Style from "../../assets/scss/tabs.module.scss";

export default function Tabs(props) {
  return (
    <React.Fragment>
      <div className={Style.tabSection}>
        <ul>
          <li>
            <NavLink
              className={props.active === 3 ? Style.active : ""}
              to="/month"
            >
              Monthly
            </NavLink>
          </li>
          <li>
            <NavLink
              className={props.active === 2 ? Style.active : ""}
              to="/quarter"
            >
              Quarterly
            </NavLink>
            <div className={`${Style.saving} ${Style.saving_top}`}>
              save 10%
            </div>
          </li>
          <li>
            <NavLink
              className={props.active === 1 ? Style.active : ""}
              to="/annual"
            >
              Annually
            </NavLink>
            <div className={`${Style.saving} ${Style.saving_bottom}`}>
              save 15%
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
