import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FaqSetting from "./FaqSetting";
// require("bootstrap/dist/css/bootstrap.min.css");
const Dashboard = () => {
  const location = useLocation();
  useEffect(() => {
    // if (location.pathname.includes("/update-faq")) {
    //   require("bootstrap/dist/css/bootstrap.min.css");
    // }
  }, []);
  return (
    <div>
      <FaqSetting />
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
