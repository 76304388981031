import React, { useState } from "react";
import {
  freeTrialLink,
  quarterAdvancedLink,
  quarterAdvanced_CILink,
  quarterStandardLink,
} from "../../Plans/Links/Links";
import {
  INquarterAdvancedPrice,
  INquarterAdvanced_CIPrice,
  INquarterStandardPrice,
  quarterAdvancedPrice,
  quarterAdvanced_CIPrice,
  quarterStandardPrice,
} from "../../Plans/Price/Price";
import Banner from "../Banner/Banner";
import Banner2 from "../Banner/Banner2";
import VatTxtBanner from "../Banner/VatTxtBanner";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeroText from "../HeroText/HeroText";
import Package from "../Packages/Package";
import PackageMobile from "../Packages/PackageMobile";
import ScopeService from "../ScopeService/ScopeService";
import Tabs from "../Tabs/Tabs";

export default function QuarterTab() {
  const [desktopPackage, setDesktopPackage] = useState(true);

  setInterval(() => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    const width = getWidth();
    // console.log(width)
    if (width >= 524) {
      setDesktopPackage(true);
    } else {
      setDesktopPackage(false);
    }
  }, 1000);

  const Plan = {
    activePlan: 2,
    duration: "mo",
    quarterStandardPrice: quarterStandardPrice,
    quarterAdvancedPrice: quarterAdvancedPrice,
    quarterAdvanced_CIPrice: quarterAdvanced_CIPrice,
    INquarterStandardPrice: INquarterStandardPrice,
    INquarterAdvancedPrice: INquarterAdvancedPrice,
    INquarterAdvanced_CIPrice: INquarterAdvanced_CIPrice,
    quarterStandardLink: quarterStandardLink,
    quarterAdvancedLink: quarterAdvancedLink,
    quarterAdvanced_CILink: quarterAdvanced_CILink,
    freeTrialLink: freeTrialLink,
  };
  return (
    <React.Fragment>
      <Header />
      <HeroText />
      <Tabs active={2} />
      {desktopPackage ? <Package plan={Plan} /> : <PackageMobile plan={Plan} />}
      <VatTxtBanner />
      <Banner />
      <ScopeService />
      <FAQ />
      <Banner2 />
      <Footer />
    </React.Fragment>
  );
}
