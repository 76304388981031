import React, { useState } from "react";
import "../../assets/scss/global.scss";
import Style from "../../assets/scss/scopeservice.module.scss";
import { AdvancedData, StandardData, EssentialVideos } from "../../data/Data";

export default function ScopeService() {
  //   const [standard, setStandard] = useState(true);
  //   const [advanced, setAdvanced] = useState(false);
  //   const [essentialVideoDesigns, setEssentialsVideoDesigns] = useState(false);
  const [currentTab, setTab] = useState(0);
  const [desktopWidth, setDesktopWidth] = useState(true);

  setInterval(() => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    const width = getWidth();
    // console.log(width)
    if (width >= 524) {
      setDesktopWidth(true);
    } else {
      setDesktopWidth(false);
    }
  }, 1000);

  const next = () => {
    if (currentTab >= 0 || currentTab <= 2) {
      setTab(currentTab + 1);
    }
  };

  const prev = () => {
    if (currentTab >= 0 || currentTab <= 2) {
      setTab(currentTab - 1);
    }
  };

  //   const ScopeTabOne = (e) => {
  //     if (e.target.checked) {
  //       setStandard(e.target.checked);
  //       setAdvanced(false);
  //       setEssentialsVideoDesigns(false);
  //     } else {
  //       setStandard(e.target.checked);
  //     }
  //   };
  //   const ScopeTabTwo = (e) => {
  //     if (e.target.checked) {
  //       setAdvanced(e.target.checked);
  //       setStandard(false);
  //       setEssentialsVideoDesigns(false);
  //     } else {
  //       setAdvanced(e.target.checked);
  //     }
  //   };
  //   const ScopeTabThree = (e) => {
  //     if (e.target.checked) {
  //       setEssentialsVideoDesigns(e.target.checked);
  //       setStandard(false);
  //       setAdvanced(false);
  //     } else {
  //       setEssentialsVideoDesigns(e.target.checked);
  //     }
  //   };

  const ScopeTab = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <ul className={Style.content}>
              {StandardData.map((data, idx) => (
                <li key={idx}>
                  <div className={Style.scopeImage}>
                    <img
                      src={require(`../../assets/icons/${data.img}`)}
                      alt={data.name}
                    />
                  </div>

                  <h4>{data.name}</h4>
                  {/* {desktopWidth ? <p>{data.detail}</p> : null} */}
                </li>
              ))}
            </ul>
            <div className="txt-center space">
              <button className="button">View More</button>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <ul className={Style.content}>
              {AdvancedData.map((data, idx) => (
                <li key={idx}>
                  <div className={Style.scopeImage}>
                    <img
                      src={require(`../../assets/icons/${data.img}`)}
                      alt={data.name}
                    />
                  </div>
                  <h4>{data.name}</h4>
                  {/* {desktopWidth ? <p>{data.detail}</p> : null} */}
                </li>
              ))}
            </ul>
            <div className="txt-center space">
              <button className="button">View More</button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <ul className={Style.content}>
              {EssentialVideos.map((data, idx) => (
                <li key={idx}>
                  <div className={Style.scopeImage}>
                    <img
                      style={idx === 0 ? { width: "66%" } : { width: "60%" }}
                      src={require(`../../assets/icons/${data.img}`)}
                      alt={data.name}
                    />
                  </div>
                  <h4>{data.name}</h4>
                  {/* {desktopWidth ? <p>{data.detail}</p> : null} */}
                </li>
              ))}
            </ul>
            <div className="txt-center space">
              <button className="button">View More</button>
            </div>
          </>
        );
      default:
        return "no data";
    }
  };
  return (
    <React.Fragment>
      <div className={Style.boxPhone + " box"}>
        <div
          className=""
          style={{
            borderRadius: "24px",
            boxShadow: "-3px 0px 21px -2px #fff, 21px 20px 13px -15px #d6d6d6",
            padding: "25px 34px",
          }}
        >
          <h2 className={Style.sectionTitle}>Scope of Service</h2>
          <div className={Style.Tabs}>
            <ul>
              <li>
                <label
                  className={
                    currentTab === 0 && desktopWidth ? Style.active : ""
                  }
                  htmlFor="scopeOne"
                >
                  Graphics
                </label>
                <input
                  type="radio"
                  onClick={() => setTab(0)}
                  name="switch"
                  id="scopeOne"
                />
              </li>
              <li>
                <label
                  className={
                    currentTab === 1 && desktopWidth ? Style.active : ""
                  }
                  htmlFor="scopeTwo"
                >
                  Graphics+
                </label>
                <input
                  type="radio"
                  onClick={() => setTab(1)}
                  name="switch"
                  id="scopeTwo"
                />
              </li>
              <li>
                <label
                  className={
                    currentTab === 2 && desktopWidth ? Style.active : ""
                  }
                  htmlFor="scopeThree"
                >
                  {/* {desktopWidth ? "Essential Videos" : "Video"} */}
                  Essential Videos
                </label>
                <input
                  type="radio"
                  onClick={() => setTab(2)}
                  name="switch"
                  id="scopeThree"
                />
              </li>
            </ul>
          </div>

          <div className={Style.MobileTabs}>
            <div className={Style.flex}>
              <div>
                {" "}
                <button
                  className={Style.arrow}
                  onClick={prev}
                  disabled={currentTab === 0 ? true : ""}
                >
                  <i className="fa-solid fa-caret-left"></i>
                </button>
              </div>
              <div className={Style.MobileTabName}>
                {currentTab === 0
                  ? "Graphics"
                  : currentTab === 1
                  ? "Graphics+"
                  : currentTab === 2
                  ? "Essential Videos"
                  : ""}
              </div>
              <div>
                <button
                  className={Style.arrow}
                  onClick={next}
                  disabled={currentTab === 2 ? true : ""}
                >
                  <i className="fa-solid fa-caret-right"></i>
                </button>
              </div>
            </div>
          </div>
          {/* {standard ? (
            <>
              <ul className={Style.content}>
                {StandardData.map((data, idx) => (
                  <li key={idx}>
                    <div className={Style.scopeImage}>
                      <img
                        src={require(`../../assets/icons/${data.img}`).default}
                        alt={data.name}
                      />
                    </div>
                    <h4>{data.name}</h4>
                    {desktopWidth ? <p>{data.detail}</p> : null}
                  </li>
                ))}
              </ul>
              <div className="txt-center space">
                <button className="button">View More</button>
              </div>
            </>
          ) : (
            ""
          )}

          {advanced ? (
            <>
              <ul className={Style.content}>
                {AdvancedData.map((data, idx) => (
                  <li key={idx}>
                    <div className={Style.scopeImage}>
                      <img
                        src={require(`../../assets/icons/${data.img}`).default}
                        alt={data.name}
                      />
                    </div>
                    <h4>{data.name}</h4>
                    {desktopWidth ? <p>{data.detail}</p> : null}
                  </li>
                ))}
              </ul>
              <div className="txt-center space">
                <button className="button">View More</button>
              </div>
            </>
          ) : (
            ""
          )}

          {essentialVideoDesigns ? (
            <>
              <ul className={Style.content}>
                {StandardData.map((data, idx) => (
                  <li key={idx}>
                    <div className={Style.scopeImage}>
                      <img
                        src={require(`../../assets/icons/${data.img}`).default}
                        alt={data.name}
                      />
                    </div>
                    <h4>{data.name}</h4>
                    {desktopWidth ? <p>{data.detail}</p> : null}
                  </li>
                ))}
              </ul>
              <div className="txt-center space">
                <button className="button">View More</button>
              </div>
            </>
          ) : (
            ""
          )} */}
          {ScopeTab(currentTab)}
        </div>
      </div>
    </React.Fragment>
  );
}
