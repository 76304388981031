import React, { useState } from "react";

import Style from "../../assets/scss/footer.module.scss";
import "../../assets/scss/global.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMobileAlt,
  faAngleDown,
  faAngleUp,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const FooterMobile = () => {
  const [toggle, setToggle] = useState({
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: true,
  });

  return (
    <>
      {/* footer mobile view start  */}
      <section className={Style.Mobile_Two}>
        <ul>
          <li
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() =>
              setToggle({
                ...toggle,
                section2: !toggle.section2,
                section3: false,
                section4: false,
                section5: false,
                section6: false,
              })
            }
          >
            {" "}
            <span> Company </span>{" "}
            <FontAwesomeIcon
              className={Style.icon}
              icon={!toggle.section2 ? faAngleDown : faAngleUp}
            />{" "}
          </li>
          <div className={toggle.section2 ? Style.d_block : Style.d_none}>
            <li>
              <a href="https://doodlspace.com/about-us">About Us</a>
            </li>
            <li>
              <a href="https://doodlspace.com/portfolio/">Our Work</a>
            </li>
          </div>
          {/* <li><a href="/">Careers</a></li> */}
        </ul>
      </section>
      <section className={Style.Mobile_Three}>
        <ul>
          <li
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() =>
              setToggle({
                ...toggle,
                section3: !toggle.section3,
                section2: false,
                section4: false,
                section5: false,
                section6: false,
              })
            }
          >
            {" "}
            <span> Service </span>{" "}
            <FontAwesomeIcon
              className={Style.icon}
              icon={!toggle.section3 ? faAngleDown : faAngleUp}
            />{" "}
          </li>
          <div className={toggle.section3 ? Style.d_block : Style.d_none}>
            <li>
              <a href="https://doodlspace.com/services-startups/">
                For Start Ups/Small Businesses
              </a>
            </li>
            <li>
              <a href="https://doodlspace.com/services-marketing-team/">
                For Marketing Teams
              </a>
            </li>
            <li>
              <a href="https://doodlspace.com/services-agencies/">
                For Agencies
              </a>
            </li>
            <li>
              <a href="https://subscription.doodlspace.com/">Pricing</a>
            </li>
          </div>
        </ul>
      </section>
      <section className={Style.Mobile_Four}>
        <ul>
          <li
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() =>
              setToggle({
                ...toggle,
                section4: !toggle.section4,
                section2: false,
                section3: false,
                section5: false,
                section6: false,
              })
            }
          >
            {" "}
            <span> Legal </span>{" "}
            <FontAwesomeIcon
              className={Style.icon}
              icon={!toggle.section4 ? faAngleDown : faAngleUp}
            />{" "}
          </li>
          <div className={toggle.section4 ? Style.d_block : Style.d_none}>
            <li>
              <a href="/">Terms of Services</a>
            </li>
            <li>
              <a href="/">Privacy Policy</a>
            </li>
          </div>
        </ul>
      </section>
      <section className={Style.Mobile_Five}>
        <ul>
          <li
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() =>
              setToggle({
                ...toggle,
                section5: !toggle.section5,
                section2: false,
                section3: false,
                section4: false,
                section6: false,
              })
            }
          >
            {" "}
            <span> Resources </span>{" "}
            <FontAwesomeIcon
              className={Style.icon}
              icon={!toggle.section5 ? faAngleDown : faAngleUp}
            />{" "}
          </li>
          <div className={toggle.section5 ? Style.d_block : Style.d_none}>
            <li>
              <a href="https://doodlspace.com/blogs/">Blog</a>
            </li>
            <li>
              <a href="https://doodlspace.com/services-startups/#">
                Knowledge Base
              </a>
            </li>
            {/* <li><a href="/">Guides</a></li> */}
            {/* <li><a href="/">Press Kit</a></li> */}
          </div>
        </ul>
      </section>
      <section className={Style.Mobile_Six}>
        <ul>
          <li
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={() =>
              setToggle({
                ...toggle,
                section6: !toggle.section6,
                section2: false,
                section3: false,
                section4: false,
                section5: false,
              })
            }
          >
            {" "}
            <span> Contact </span>{" "}
            <FontAwesomeIcon
              className={Style.icon}
              icon={!toggle.section6 ? faAngleDown : faAngleUp}
            />{" "}
          </li>
          <div className={toggle.section6 ? Style.d_block : Style.d_none}>
            <li
              style={{
                display: "flex",
                marginTop: "8px",
                width: "90%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <a
                className={Style.smallFont12}
                style={{ padding: "5px 0px " }}
                href="tel:+919123961265"
              >
                <span>
                  <FontAwesomeIcon icon={faMobileAlt} />
                </span>
                +91 9123961265
              </a>
              <a
                className={Style.smallFont12}
                style={{ padding: "5px 0px " }}
                href="mailto:info@doodlspace.com"
              >
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                info@doodlspace.com
              </a>
            </li>
            <li style={{ marginTop: "8px" }}>
              <a href="/">
                <span>
                  {" "}
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                </span>

                <span className={Style.smallFont12}>
                  {" "}
                  660-E, A-Wing, 6th Floor, B.D. Patel House,{" "}
                </span>
                <br />
                <span
                  className={Style.smallFont12}
                  style={{ margin: "5px 0px 0px 26px", display: "block" }}
                >
                  Naranpura, Ahmedabad 380014
                </span>
              </a>
            </li>
          </div>
        </ul>

        <p>Follow us on:</p>
        <ul className={Style.social}>
          <li>
            <a href="https://linkedin.com/company/doodlspace">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li>
            <a href="https://facebook.com/doodlspace">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a href="https://instagram.com/doodlspace">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>
      </section>

      {/* footer mobile view end  */}
    </>
  );
};

export default FooterMobile;
