import React, { useState } from "react";
import {
  freeTrialLink,
  monthAdvancedLink,
  monthAdvanced_CILink,
  monthStandardLink,
} from "../../Plans/Links/Links";
import {
  INmonthAdvancedPrice,
  INmonthAdvanced_CIPrice,
  INmonthStandardPrice,
  monthAdvancedPrice,
  monthAdvanced_CIPrice,
  monthStandardPrice,
} from "../../Plans/Price/Price";
import Banner from "../Banner/Banner";
import Banner2 from "../Banner/Banner2";
import VatTxtBanner from "../Banner/VatTxtBanner";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeroText from "../HeroText/HeroText";
import Package from "../Packages/Package";
import PackageMobile from "../Packages/PackageMobile";
import ScopeService from "../ScopeService/ScopeService";
import Tabs from "../Tabs/Tabs";

export default function MonthTab() {
  const [desktopPackage, setDesktopPackage] = useState(true);

  setInterval(() => {
    function getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    }
    const width = getWidth();
    // console.log(width)
    if (width >= 524) {
      setDesktopPackage(true);
    } else {
      setDesktopPackage(false);
    }
  }, 1000);

  const Plan = {
    activePlan: 3,
    duration: "mo",
    monthStandardPrice: monthStandardPrice,
    monthAdvancedPrice: monthAdvancedPrice,
    monthAdvanced_CIPrice: monthAdvanced_CIPrice,
    INmonthStandardPrice: INmonthStandardPrice,
    INmonthAdvancedPrice: INmonthAdvancedPrice,
    INmonthAdvanced_CIPrice: INmonthAdvanced_CIPrice,
    monthStandardLink: monthStandardLink,
    monthAdvancedLink: monthAdvancedLink,
    monthAdvanced_CILink: monthAdvanced_CILink,
    freeTrialLink: freeTrialLink,
  };
  return (
    <React.Fragment>
      <Header />
      <HeroText />
      <Tabs active={3} />
      {desktopPackage ? <Package plan={Plan} /> : <PackageMobile plan={Plan} />}
      <VatTxtBanner />
      <Banner />
      <ScopeService />
      <FAQ />
      <Banner2 />
      <Footer />
    </React.Fragment>
  );
}
