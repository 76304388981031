import axios from "axios";
// import Axios from "axios";
import React, { useEffect, useState } from "react"; // useEffect,
import Style from "../../assets/scss/faq.module.scss";
import "../../assets/scss/global.scss";
import ViewFaq from "./ViewFaq";

export default function FAQ() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const baseURl = process.env.REACT_APP_API_URL;
    setLoading(true);
    axios
      .get(`${baseURl}/faqs`)
      .then((res) => {
        setFaqs(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.active = !faq.active;
        } else {
          faq.active = false;
        }
        return faq;
      })
    );
  };

  return (
    <React.Fragment>
      <div className={`box ${Style.boxMargin}`}>
        <div className={Style.all_faqs}>
          <div>
            <img src={require("../../assets/icons/Flag.svg").default} alt="" />
          </div>
          <h2 className={Style.sectionTitle}>FAQ’s</h2>
          <ul>
            {loading && (
              <li style={{ textAlign: "center" }}>
                <span className="loader"></span>
              </li>
            )}
            {faqs.map((data, idx) => (
              <ViewFaq
                faqs={data}
                index={idx}
                key={idx}
                toggleFAQ={toggleFAQ}
              />
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
