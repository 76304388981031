import {
  faMinus,
  faPenToSquare,
  faTrashCanArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./FaqSetting.css";

const FaqSetting = () => {
  const baseURl = process.env.REACT_APP_API_URL;
  const [addFaq, setAddFaq] = useState({
    question: "",
    answer: "",

    answer2: "",
    answer3: "",
    loading: false,
  });
  const [isReloadData, setIsReloadData] = useState(false);
  const [UpdateFaqID, setUpdateFaqID] = useState(null);
  const [deleteCat, setDeleteCat] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [faq, setFaq] = useState({ loading: true, result: [] });

  useEffect(() => {
    axios
      .get(`${baseURl}/faqs`)
      .then((res) => {
        setFaq({ ...faq, loading: false, result: [...res.data.results] });
      })
      .catch((err) => {
        setFaq({ ...faq, loading: false, result: [] });
        toast.error("Server Error Try again later ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [isReloadData]);

  // useEffect(() => {
  //   dispatch(fetchActivityCategory())
  // }, [activityStatus.success, deleteCat])

  const handelAddFAQ = async (e) => {
    e.preventDefault();
    setAddFaq({ ...addFaq, loading: true });
    const data = {
      question: addFaq.question,
      answer: addFaq.answer,
      answer2: addFaq.answer2,
      answer3: addFaq.answer3,
    };

    try {
      if (UpdateFaqID) {
        await axios.post(`${baseURl}/edit-faq/${UpdateFaqID}`, data, {
          headers: {
            key: "doodlSpace_FAQ",
          },
        });
      } else {
        await axios.post(baseURl + "/create-faq", data, {
          headers: {
            key: "doodlSpace_FAQ",
          },
        });
      }

      setAddFaq({
        ...addFaq,
        question: "",
        answer: "",

        answer2: "",
        answer3: "",
        loading: false,
      });

      toast.success("SuccessFully updated", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsReloadData((value) => !value);
    } catch (error) {
      toast.error("Server Error Try again later ", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setAddFaq({ ...addFaq, loading: false });
    }
  };

  const handelDeleteFAQ = async (id) => {
    setDeletedId(id);
    setDeleteLoading(true);
    try {
      const res = await axios.post(
        `${baseURl}/delete-faq/${id}`,
        {},
        {
          headers: {
            key: "doodlSpace_FAQ",
          },
        }
      );

      toast.success("Successfully deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsReloadData((value) => !value);
      setDeleteCat(!deleteCat);
      setDeleteLoading(false);
    } catch (error) {
      toast.error("Server Error Try again later ", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setDeleteLoading(false);
  };

  const handelEditFAQ = (data) => {
    setUpdateFaqID(data._id);
    setAddFaq({
      addFaq,
      question: data.question,
      answer: data.answer,
      answer2: data.answer2 ? data.answer2 : "",
      answer3: data.answer3 ? data.answer3 : "",
    });
  };
  const handelUpdateCancel = () => {
    setUpdateFaqID(null);
    setAddFaq({ addFaq, question: "", answer: "", answer2: "", answer3: "" });
  };

  return (
    <div className="box">
      <div className="row mt-3">
        <div className="left-inputbox">
          <div className="card p-4">
            <h3 className="text-center"> Create a FAQ </h3>

            <div className="formContainer p-3 ">
              <form onSubmit={handelAddFAQ}>
                <input
                  className="form-control"
                  value={addFaq.question}
                  onChange={(e) =>
                    setAddFaq({ ...addFaq, question: e.target.value })
                  }
                  placeholder="question name"
                  type="text"
                  name="question"
                  required
                />
                <textarea
                  rows="6"
                  className="form-control my-2"
                  value={addFaq.answer}
                  onChange={(e) =>
                    setAddFaq({ ...addFaq, answer: e.target.value })
                  }
                  placeholder="Answer one"
                  type="text"
                  name="answer"
                  required
                />

                <textarea
                  rows="4"
                  className="form-control my-2"
                  value={addFaq.answer2}
                  onChange={(e) =>
                    setAddFaq({ ...addFaq, answer2: e.target.value })
                  }
                  placeholder="Answer two"
                  type="text"
                  name="answer2"
                />
                <textarea
                  rows="2"
                  className="form-control"
                  value={addFaq.answer3}
                  onChange={(e) =>
                    setAddFaq({ ...addFaq, answer3: e.target.value })
                  }
                  placeholder="Answer three"
                  type="text"
                  name="answer3"
                />

                <button
                  type="submit"
                  className={
                    UpdateFaqID
                      ? "btn update-button mt-3"
                      : "btn add-button mt-3"
                  }
                  disabled={addFaq.loading}
                >
                  {addFaq.loading
                    ? "wait..."
                    : UpdateFaqID
                    ? "Update Faq"
                    : "Add Faq"}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="right-faq">
          <div className="card p-4">
            <h3 className="text-center mb-3"> List of FAQ </h3>

            {faq.loading ? (
              <div className="row" style={{ marginTop: "20px" }}>
                <div
                  style={{ height: "30vh" }}
                  className="col-12 d-flex justify-content-center align-items-center "
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="faqList-wrapper">
                {faq?.result.length > 0 &&
                  faq.result.map((data, index) => {
                    return (
                      <div
                        className={
                          UpdateFaqID === data._id
                            ? "d-flex my-2  p-2  shadow-lg faqList"
                            : "d-flex my-2  p-2  shadow-sm faqList"
                        }
                        key={index}
                      >
                        <div className={"w-100 "}>
                          {index + 1}. {data.question}
                        </div>

                        <div className="faActionContainer">
                          <div
                            className="flex-shrink-1 cursor"
                            style={{ color: "green", marginRight: "20px" }}
                          >
                            <strong
                              onClick={() => {
                                UpdateFaqID === data._id
                                  ? handelUpdateCancel()
                                  : handelEditFAQ(data);
                              }}
                            >
                              {UpdateFaqID === data._id ? (
                                <FontAwesomeIcon icon={faMinus} />
                              ) : (
                                <FontAwesomeIcon icon={faPenToSquare} />
                              )}
                            </strong>
                          </div>
                          {UpdateFaqID !== data._id && (
                            <div
                              className="flex-shrink-1 cursor"
                              style={{ color: "red" }}
                            >
                              <strong
                                onClick={() => {
                                  handelDeleteFAQ(data._id);
                                }}
                              >
                                {" "}
                                {deletedId === data._id && deleteLoading ? (
                                  "wait..."
                                ) : (
                                  <FontAwesomeIcon icon={faTrashCanArrowUp} />
                                )}
                              </strong>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSetting;
