import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import MonthTab from "./components/MonthTab/MonthTab";
import { errorRoute, tabRoute } from "./routes/Routes";
export default function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={MonthTab} />
          {tabRoute.map((route, idx) => (
            <Route path={route.path} component={route.component} key={idx} />
          ))}
          {errorRoute.map((route, idx) => (
            <Route path={route.path} component={route.component} key={idx} />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}
