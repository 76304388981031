import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import Style from "../../assets/scss/faq.module.scss";

export default function ViewFaq(props) {
  const { faqs, index, toggleFAQ } = props;
  console.log(faqs, "faqs");

  useEffect(() => {
    if (index === 0) {
      faqs.active = true;
    }
  }, [faqs, index]);

  return (
    <li
      className={faqs.active ? Style.viewFAQStyle : ""}
      onClick={() => toggleFAQ(index)}
    >
      <h3>
        <span>
          {faqs.active ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </span>
        {faqs.question}
      </h3>
      <p className={faqs.active ? Style.viewFAQ : Style.hideFAQ}>
        <p>
          {sessionStorage.getItem("detCountry") === "IN" &&
          faqs.question.includes("Are there any hidden fees")
            ? "No. All you pay is what you see plus taxes (if applicable)."
            : faqs.answer}
        </p>
        {faqs.answer2 && <p>{faqs.answer2}</p>}
        {faqs.answer3 && <p>{faqs.answer3}</p>}
      </p>
    </li>
  );
}

// comment
