// Annual Payment Links
const annualStandardLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const annualAdvancedLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const annualAdvanced_CILink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";

// Quarter Payment Links
const quarterStandardLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const quarterAdvancedLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const quarterAdvanced_CILink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";

// Month Payment Links
const monthStandardLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const monthAdvancedLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";
const monthAdvanced_CILink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";

// Trial Link
const freeTrialLink =
  "https://crm-doodlspace.myfreshworks.com/crm/sales/web_forms/8c302a1eff59a0828f95ea3213c4c9bfd5aded63d7f6f9384491dd54306690cf/form.html";

export {
  annualStandardLink,
  annualAdvancedLink,
  annualAdvanced_CILink,
  quarterStandardLink,
  quarterAdvancedLink,
  quarterAdvanced_CILink,
  monthStandardLink,
  monthAdvancedLink,
  monthAdvanced_CILink,
  freeTrialLink,
};
